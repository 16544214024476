import Notify from 'api/Notify'
import { IAllNotification, INotifications } from 'types/NotifyInterface'
import { AppStore } from 'utils'
import { RemoteConfigValues, isVisible } from 'utils/CheckVisibleElement'
import { create } from 'zustand'

interface NotifyStore {
  isLoadingNotifications: boolean
  setIsLoadingNotifications: (isLoading: boolean) => void

  /// _______

  workspaceNotifications: INotifications[] | []
  setWorkspaceNotifications: (notify: INotifications[]) => void

  update: () => void
  // setFullNotifications: (notify: INotifications[]) => void

  allWorkspacesNotifications: IAllNotification[] | []
  setAllWorkspacesNotifications: (notify: IAllNotification[]) => void
  updateNotifications: () => Promise<boolean>

  updateDashboard: number
  setUpdatedDashboard: () => void

  addNotificationReaded: (notifyId: string) => void
  addAllNotificationReader: () => void

  // utils

  getWorkspaceNotificationsNumber: (id) => number

  clearNotifications: () => void
}

export const useNotifyStore = create<NotifyStore>((set, get) => ({
  isLoadingNotifications: true,
  setIsLoadingNotifications: (isLoading: boolean) => {
    set({ isLoadingNotifications: isLoading })
  },

  workspaceNotifications: [],
  setWorkspaceNotifications: (notify: INotifications[]) => {
    set({ workspaceNotifications: notify })
  },

  allWorkspacesNotifications: [],
  setAllWorkspacesNotifications: (notify: IAllNotification[]) => {
    set({ allWorkspacesNotifications: notify })
  },

  setFullNotifications: (notify: INotifications[]) => {
    set({ workspaceNotifications: notify })
  },

  update: async () => {
    set({ isLoadingNotifications: true })
    try {
      const notification = await Notify.getNotification()
      set({ workspaceNotifications: notification })
    } catch (error: any) {
      console.error(error)
    }
    set({ isLoadingNotifications: false })
  },

  updateNotifications: async () => {
    set({ isLoadingNotifications: true })
    return new Promise(async (resolve) => {
      if (!isVisible(RemoteConfigValues.NotificationDisabled)) {
        console.error('------NotificationDisabled-----')
        set({ isLoadingNotifications: false })
        return resolve(false)
      }
      try {
        const notification = await Notify.getNotification()
        set({ workspaceNotifications: notification })

        const allNotifications = await Notify.getWorkspacesNotifications()
        set({ allWorkspacesNotifications: allNotifications })
        resolve(true)
        set({ isLoadingNotifications: false })
      } catch (error: any) {
        console.error(error)
        resolve(false)
        set({ isLoadingNotifications: false })
      }
    })
  },

  updateDashboard: 0,
  setUpdatedDashboard: async () => {
    //get updated dashboard
    const dashboard = get().updateDashboard
    set({ updateDashboard: dashboard + 1 })
  },

  addNotificationReaded: (notifyId: string) => {
    const { workspaceNotifications } = get()

    const findNotify = workspaceNotifications.find((n) => n._id === notifyId)
    findNotify!.notificationReaded.push(AppStore.loggedUser._id)

    set({ workspaceNotifications: [...workspaceNotifications] })
  },

  clearNotifications: () => {
    set({ allWorkspacesNotifications: [] })
  },

  addAllNotificationReader: async () => {
    const { workspaceNotifications } = get()

    const newWorkspaceNotifications = workspaceNotifications.map((n) => {
      return { ...n, notificationReaded: [...n.notificationReaded, AppStore.loggedUser._id] }
    })

    set({ workspaceNotifications: newWorkspaceNotifications })
  },

  getWorkspaceNotificationsNumber: (id) => {
    const { allWorkspacesNotifications } = get()
    const notifyNumberLength = allWorkspacesNotifications?.filter((n) => n.id === id)?.[0]?.notificationLength ?? 0
    return notifyNumberLength
  },
}))
