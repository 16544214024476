import User from 'api/user'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'
import { firebaseConfig } from './config'

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APY_KEY ?? 'AIzaSyACdfkZbk4rRGtUP_wr1W_W8XjciTN4iYQ',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? 'asters-qty.firebaseapp.com',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? 'asters-qty',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? 'asters-qty.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? '147648995069',
  appId: process.env.REACT_APP_FIREBASE_APP_ID ?? '1:147648995069:web:b50203f3462d3c48cde894',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? 'G-X0GSC0YCCQ',
})
const messaging = getMessaging(app)

//service worker
let sw = null

navigator.serviceWorker.register('firebase-messaging-sw.js').then((reg) => {
  if (reg.active) {
    sw = reg
  }
})

// Permessi di notifica della pagina
export function requestPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      // Ottiene token dell'utente
      console.log('Permission granted', process.env.REACT_APP_FIREBASE_VAPID_KEY)
      getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        serviceWorkerRegistration: sw,
      })
        .then((currentToken) => {
          if (currentToken) {
            //TODO: Dal currentToken si possono inviare le notifiche all'utente
            User.addToken(currentToken)
          } else {
            console.error('Can not get token')
          }
        })
        .catch((e) => {
          if (e.message.includes('SSL')) {
            console.error(
              'Su localhost non funzionano le notifiche push, utilizza NGROK. comando -> ngrok http https://localhost:3000/'
            )
          } else {
            console.error('e', e)
          }
        })
    } else {
    }
  })
}
