import styled from '@emotion/styled'
import BillingService from 'api/service/BillingService'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import React from 'react'
import { useUserStore } from 'stores/UserStore'
import { PlanResource, SubscriptionPlan } from 'types/SubscriptionInterfaces'
import { AppStore, T, capitalizeFirstLetter, showError } from 'utils'
import { UpgradeOptionsModal } from './UpgradeOptions/UpgradeOptionsModal'

interface PlanCardProps {
  suggested: boolean
  trialEnabled?: boolean
}

interface UpgradePlanProps {
  containExpiredPlan?: boolean
  plan: SubscriptionPlan
  status: string
  activePlan?: SubscriptionPlan
  trialEnabled?: boolean
  requestEnabled?: boolean
  currentPlanOrder?: number
}

export const UpgradePlan: React.FC<UpgradePlanProps> = ({
  containExpiredPlan,
  plan,
  status,
  trialEnabled,
  requestEnabled,
  activePlan,
  currentPlanOrder,
}: UpgradePlanProps) => {
  // const isActive = status === 'active'
  const isExpired = status === 'expired'
  const isDowngrade = currentPlanOrder && plan.order < currentPlanOrder
  const isNextInOrder = currentPlanOrder && plan.order === currentPlanOrder + 1

  /**
   *
   * @returns
   */

  const { currentLanguage, currentUser } = useUserStore((state) => ({
    currentLanguage: state.user?.language ?? 'it',
    currentUser: state.user,
  }))

  const isSuggested = (currentUser?.subscription?.isTrialEnabled && plan.suggested) ?? false

  const getButtonText = () => {
    //requestEnabled return T.billing.plans.downgradeNotEnabled
    if (isExpired) return T.billing.plans.activate
    if (!plan.upgradable) return T.billing.plans.reserveDemo
    if (trialEnabled) return T.billing.plans.startTrial

    const upgradeText = !isDowngrade ? T.billing.plans.upgrade : T.billing.plans.downgrade
    return `${upgradeText} ${plan.data[currentLanguage].title}`
  }

  /**
   *
   * @param limit
   * @param label
   * @returns
   */
  const formatLimit = (limit?: { infinite: boolean; total: number }, label?: string) => {
    if (!limit) return ' '
    if (limit.infinite) return `${label} illimitati`
    return `${limit.total} ${label}`
  }

  /**
   *
   * @param feature
   * @param label
   * @returns
   */
  const formatFeature = (feature?: boolean, label?: string) => (feature ? label : `     `)

  // ------ METHODS -------

  /**
   *
   */
  const handleUpgrade = async () => {
    try {
      if (!plan.upgradable || isDowngrade) {
        const message = isDowngrade ? T.billing.plans.downgradeNotEnabled : T.billing.plans.reserveDemo
        // Caso : Enterprise --- Siamo casistica enterprise. L'utente deve contattare il supporto
        // @ts-ignore
        window.Intercom('showNewMessage', message)
        return
      }

      // const expirationDate = AppStore.loggedUser?.plan?.expirationDate ?? 0
      // const isNewSubscription = expirationDate < new Date().getTime()
      const user = useUserStore.getState().user
      const currentPlan = user?.subscription?.activePlan

      if (currentPlan && currentPlan.nextPlanId) {
        showError(T.billing.upgradePlan.upgradePlanned)
        return
      }
      // Controlla se il piano attuale è scaduto
      const isPlanExpired =
        (currentPlan && currentPlan?.expirationDate && new Date(currentPlan?.expirationDate) < new Date()) ||
        !currentPlan?.expirationDate

      // Caso : Abbonamento scaduto o mancante

      // if order is stil upper but plan.yearly is true but the activePlan.yearly is false then let him go to the upgrade
      if (currentPlan && currentPlan?.plan?.order > plan.order) {
        // Allow upgrade from monthly to yearly plan
        return showError(T.billing.plans.downgradeNotEnabled)
      }

      if (currentPlan?.plan === undefined || isPlanExpired) {
        requestPlanGeneration()
        return
      }

      //const link = await BillingService.upgradePlan(plan._id, false)
      //window.open(link.data.link, '_blank')

      if (user && !isPlanExpired) requestPlanUpgrade()
    } catch (e) {
      console.error(e)
      showError(e)
    }
  }

  /**
   * Richiede la generazione del link per il pagamento
   */
  const requestPlanGeneration = async () => {
    try {
      // Richiedere generazione del link per il pagamento. Se il link non torna e l'error è null allora avvisare l'utente con un Toastify e refresh del logged user
      const { data }: any = await BillingService.activatePlan(plan._id)
      const link = data.link ?? data.url
      if (link) window.location.href = link
    } catch (e) {
      console.error(e)
      showError(e)
    }
  }

  /**
   *
   */
  const requestPlanUpgrade = async () => {
    return AppStore.openModal({
      id: 'upgrade-modal-confirm',
      body: (
        <Box
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: AppStore.theme.o.background,
          }}
        >
          <UpgradeOptionsModal plan={plan} />
        </Box>
      ),
      onCancel: () => {
        window.history.pushState({}, document.title, window.location.pathname + window.location.search)
      },
      style: {
        minWidth: 500,

        // minHeight: 200,
      },
    })
    //@todo - Mostrare modale con due opzioni : Upgrade immediato o Upgrade alla scadenza
  }

  /** ------------------------ UI ---------------------------- */

  const UpgradeButtonItem = () => {
    const bookable = status !== 'active'

    const specialStyle = !containExpiredPlan
      ? isNextInOrder
        ? 'selected-billing'
        : undefined
      : isExpired
      ? 'selected-billing'
      : undefined

    return bookable ? (
      <UpgradeButton
        onClick={handleUpgrade}
        style={{
          backgroundColor: !isDowngrade ? AppStore.theme.o.primary_container : AppStore.theme.o.lightestGrey,
          color: !isDowngrade ? AppStore.theme.o.on_primary_container : AppStore.theme.o.black,
          borderColor: !isDowngrade ? AppStore.theme.o.on_primary_container : AppStore.theme.o.lightGrey,
        }}
        variant={specialStyle}
      >
        {getButtonText()}
      </UpgradeButton>
    ) : (
      <FakeUpgradeButton />
    )
  }

  const PriceDescription = ({ description }) => {
    const [before, after] = description?.split('/')

    return (
      <Description>
        <span style={{ fontWeight: 700 }}>{before}</span>
        {`${after ? '/' : ''} ${after ?? ''}`}
      </Description>
    )
  }

  // Return the status of the plan
  const isActive = () => {
    if (plan.enterprise) return null

    const currentPlan = useUserStore.getState().user?.subscription?.activePlan

    // Se è previsto un upgrade pianificato ( upgrade con cancel )
    if (currentPlan?.plan.identifier !== plan.identifier && currentPlan?.nextPlanId) {
      if (currentPlan?.nextPlanId === plan.identifier) {
        return {
          color: 'textDarkGrey',
          text: T.billing.upgradePlan.planned,
        }
      }
    }

    if (currentPlan && currentPlan.plan.identifier === plan.identifier) {
      const date = currentPlan.expirationDate
      const dateString = new Date(date).toLocaleDateString('it-IT')
      console.log('dateString', dateString)
      const expired = new Date(date) < new Date()
      return {
        color: expired ? 'red' : 'purple',
        text: expired
          ? `${T.billing.upgradePlan.expiredAt} ${dateString}`
          : currentPlan?.nextPlanId
          ? `${T.billing.upgradePlan.expireAt} ${dateString}`
          : T.billing.upgradePlan.active,
      }
    }
    return null
  }

  const PlanStatus = () => {
    const value = isActive()
    if (!value) return null
    const statusText = value.text

    return <StatusTag color={value.color}>{statusText}</StatusTag>
  }

  return (
    <PlanCard suggested={isSuggested}>
      {isSuggested && <SuggestedTag color="blue">{T.billing.labels.suggested}</SuggestedTag>}

      {/** ------- HEADER : title - description - status -------- */}
      <UpgradePlanHeaderContainer>
        <UpgradePlanHeader>
          <Title>{plan?.data?.[currentLanguage]?.title}</Title>
          <PlanStatus />
        </UpgradePlanHeader>

        <PriceDescription description={plan?.data?.[currentLanguage]?.description ?? '/'} />
      </UpgradePlanHeaderContainer>

      {/** ------- CTA -------- */}
      <UpgradeButtonItem />

      {/** ------- FEATURES -------- */}
      <FeatureList>
        <FeatureItem>
          {capitalizeFirstLetter(formatLimit(plan?.limits?.socials, T.billing.labels.socialProfile))}
        </FeatureItem>
        <FeatureItem>
          {capitalizeFirstLetter(formatLimit(plan?.limits?.collaborators, T.billing.labels.colabs))}
        </FeatureItem>

        <FeatureItem>{formatFeature(plan?.features?.autopilot, T.billing.labels.autopilot)}</FeatureItem>
        <FeatureItem>{formatFeature(plan?.features?.api, T.billing.labels.api)}</FeatureItem>
        <FeatureItem>{formatFeature(plan?.features?.privateManager, T.billing.labels.privateManager)}</FeatureItem>
        <FeatureItem>{formatFeature(plan?.features?.customDevelpment, T.billing.labels.customDevelopment)}</FeatureItem>

        <FeatureItem>{formatLimit(plan?.limits?.copies, T.billing.labels.copies)}</FeatureItem>
        <FeatureItem>{formatLimit(plan?.limits?.discover, T.billing.labels.discover)}</FeatureItem>
        <FeatureItem>
          {plan?.limits?.storage && plan?.limits?.storage?.total > 0
            ? formatLimit(plan?.limits?.storage, T.billing.labels.storage)
            : formatLimit(plan?.limits?.storage, T.billing.labels.infiniteStorage)}
        </FeatureItem>
        <FeatureItem>{plan.support} support</FeatureItem>
      </FeatureList>
    </PlanCard>
  )
}

export default UpgradePlan

const UpgradePlanHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  gap: 0px;
  margin-bottom: 20px;
`
const UpgradePlanHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`

const FakeUpgradeButton = styled.div`
  padding: 12px 24px;
  min-height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
`
const UpgradeButton = styled(Button)`
  padding: 12px 24px;
  min-height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 20px;
  padding: 8px 48px 8px 48px !important;
  border: 1px solid transparent;

  &:hover {
    border-color: transparent !important;
  }
`

const PlanCard = styled.div<PlanCardProps>`
  width: 310px;
  min-height: 480px;
  max-height: 480px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  border: 1px solid transparent;
  border-color: ${(props) => (props.suggested ? AppStore.theme.o.grey : 'transparent')};
`

const Title = styled.h1`
  color: ${() => AppStore.theme.o.black};
  font-size: 32px;
  line-height: 32px;
  max-height: 32px;
  overflow: hidden;
  font-weight: 600;
  margin: 0 !important;
`

const Description = styled.p`
  color: ${() => AppStore.theme.o.black};
  font-size: 16px;
  margin: 0 !important;
`

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0 0px 0;
`

const FeatureItem = styled.li`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.5;

  min-height: 20px;
  color: ${() => AppStore.theme.o.textDarkGrey};
`

const StatusTag = styled.div<{ color: string }>`
  border-radius: 16px;
  padding: 2px 12px 2px 12px;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 0.4px;
  border: 1px solid transparent;

  background-color: ${(props) => getStatusColorBackGround(props.color)};
  color: ${(props) => getStatusColor(props.color)};
  border-color: ${(props) => getStatusColor(props.color)};
`

const SuggestedTag = styled.div`
  position: absolute;
  margin-top: -30px;
  right: 20px;
  padding: 2px 12px 2px 12px;
  letter-spacing: 0.4px;
  font-size: 10px;
  border-radius: 16px;
  border: 1px solid transparent;

  background-color: ${() => getStatusColorBackGround('purple')};
  color: ${() => getStatusColor('purple')};
  border-color: ${() => getStatusColor('purple')};
`

const getStatusColorBackGround = (status: string) => {
  switch (status) {
    case 'purple':
      return '#FAEAFE'
    case 'red':
      return AppStore.theme.o.error_container
    case 'textDarkGrey':
      return AppStore.theme.o.textDarkGrey

    default:
      return 'gray'
  }
}
const getStatusColor = (status: string) => {
  switch (status) {
    case 'purple':
      return '#732187'
    case 'red':
      return AppStore.theme.o.on_error_container
    case 'textDarkGrey':
      return AppStore.theme.o.lightGrey

    default:
      return 'gray'
  }
}
