import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Icons } from 'components/Icons'
import { NotificationIcon } from 'components/UI'
import { useEffect, useRef, useState } from 'react'
import { useNotifyStore } from 'stores/NotifyStore'
import { AppStore } from 'utils'
import { isVisible } from 'utils/CheckVisibleElement'
import NotifyContainerAbsolute from './notify/NotifyContainerAbsolute'
import { isExternalElementClicked } from 'api/Utils'

const NotifyOnHeader = ({}) => {
  /* ____________________ STATE ____________________ */
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isFourDotsMenuOpen, setIsFourDotsMenuOpen] = useState<boolean>(false)
  const { notificationCount } = useNotifyStore((s) => ({
    notificationCount:
      s.allWorkspacesNotifications?.filter((n) => n.id === AppStore.getWorkspaceId())?.[0]?.notificationLength ?? 0,
  }))
  /* ____________________ REF ____________________ */
  const notifyRef = useRef<HTMLDivElement>(null)

  /* ____________________ VARIABLES ____________________ */

  /* ____________________ METHODS ____________________ */
  const toggleOpen = () => {
    setIsOpen((prev) => !prev)
  }

  // ZUSTAND

  const { allWorkspacesNotifications } = useNotifyStore((s) => ({
    allWorkspacesNotifications: s.allWorkspacesNotifications,
  }))

  // useEffect(() => {
  //   // const notifyNumberLength =
  //   //   workspaceNotifications?.filter(
  //   //     (n) => n.workspaceId === AppStore.workspace._id && !n.notificationReaded.includes(AppStore.loggedUser._id)
  //   //   )?.length ?? 0

  //   // setNotificationCount(notifyNumberLength)

  //   const notifyNumberLength = useNotifyStore.getState().getWorkspaceNotificationsNumber(AppStore.workspace?._id ?? '')
  //   setNotificationCount(notifyNumberLength)
  // }, [allWorkspacesNotifications])

  const handleClose = (e) => {
    const elToCheck = e.target as Element
    const fourDotsMenu = document.getElementById('four-dots-menu')
    const notificationRef = notifyRef?.current

    if (elToCheck.classList.contains('chip-notify')) return
    if (isExternalElementClicked(fourDotsMenu, elToCheck)) setIsFourDotsMenuOpen(false)
    if (isExternalElementClicked(notificationRef, elToCheck)) setIsOpen(false)
  }

  const handleOpenDotsMenu = () => setIsFourDotsMenuOpen((prev) => !prev)

  /* ____________________ USEEFFECT ____________________ */
  useEffect(() => {
    if (isOpen) window.addEventListener('click', handleClose)
    return () => window.removeEventListener('click', handleClose)
  })

  return (
    <NotifyContainer forwardedRef={notifyRef} className={`${isOpen ? 'is-active' : ''}`}>
      {/* ____________ ICON CONTAINER ____________ */}
      {isVisible('header.notifyIcon') && (
        <IconContainer className="notify-bell" onClick={toggleOpen} removeHover>
          <Icons.notifyBell />
        </IconContainer>
      )}

      {/* ____________ CONTAINER WITH SCROLL CONTAINER AND OTHER ELEMENT ____________ */}
      <NotifyContainerAbsolute
        isOpen={isOpen}
        isFourDotMenuOpen={isFourDotsMenuOpen}
        handleFourDotMenuOpen={handleOpenDotsMenu}
      />

      {/* ____________ NOTIFY COUNTER WITH ANIMATION____________ */}

      <NotificationIcon
        count={notificationCount}
        style={{ position: 'absolute', top: 15, left: '35%' }}
        openNotify={toggleOpen}
      />
    </NotifyContainer>
  )
}

export default NotifyOnHeader

const NotifyContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .notify-bell svg {
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
    cursor: pointer;

    path {
      fill: ${() => AppStore.theme.o.black};
    }
  }

  &:hover,
  &.is-active {
    background-color: ${() => AppStore.theme.o.lightBlue};
    .notify-bell svg {
      border-radius: 14px;
      path {
        fill: ${() => AppStore.theme.o.blue};
      }
    }
  }

  .notification-icon-counter {
    cursor: pointer !important;
    div,
    label {
      cursor: pointer !important;
    }
  }
`
const IconContainer = styled(Box)`
  max-height: 60px;
  overflow: hidden;
`
