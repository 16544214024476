import styled from '@emotion/styled'
import { Select } from 'antd'
import Auth from 'api/Auth'
import { Tracker } from 'api/Tracker'
import Utils from 'api/Utils'
import { Box, Button, EditableInput, Icons, Text, Title } from 'components'
import { useEffect, useRef, useState } from 'react'
import { T } from 'translation/i18n'
import { IPhoneNumber } from 'types/NotifyInterface'
import { AppStore, showError, showSuccess } from 'utils'
import { TRACKEREVENTS, getAccountSettings } from 'utils/eventsTracker'
import PROFILE_PLACEHOLDER from '../../../src/assets/profile_placeholder.png'
import Medias from 'api/Medias'
import User from 'api/user'
import { useUserStore } from 'stores/UserStore'

const { Option } = Select

const AccountSection = () => {
  const [email, setEmail] = useState<string>(AppStore.loggedUser.email)
  const [name, setName] = useState<string>(AppStore.loggedUser.name)
  const [password, setPassword] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber>(
    AppStore.loggedUser.phoneNumber ?? { prefix: '', number: '', otpCode: null }
  )

  const [isOpenLanguage, setIsOpenLanguage] = useState(false)

  const [imageSrc, setImageSrc] = useState(AppStore.loggedUser.profileImage ?? PROFILE_PLACEHOLDER) //TODO: Aggiungere che se non c'è nel profile allora prende il placeholder, per ora metto direttamente il placeholder
  const fileInputRef = useRef(null)
  const [isProfileImgHovered, setIsProfileImgHovered] = useState(false)

  // Function to handle file input change
  const handleImageChange = async (event) => {
    try {
      const userId = AppStore.loggedUser._id
      const file = event.target.files[0]
      const extention = file.name.split('.').pop()
      const fileUrlLoaded = await Utils.saveImageRemoteBlob(
        file,
        `profile_image_${userId}_${new Date().getTime()}.${extention}`,
        'profileImage',
        true
      )

      if (fileUrlLoaded?.data?.url) {
        await User.updateUserProfileImage(fileUrlLoaded?.data?.url)
        useUserStore.setState({ user: { ...AppStore.loggedUser, profileImage: fileUrlLoaded?.data?.url } })
      }

      setImageSrc(fileUrlLoaded?.data?.url ?? PROFILE_PLACEHOLDER)
    } catch (error) {
      console.error(error)
    }
    //TODO: Fare chiamata che cambia effettivamente l'immagine del profilo
  }

  // Function to trigger the file input click
  const handleImageClick = () => {
    //  @ts-ignore
    if (fileInputRef && fileInputRef.current) fileInputRef.current.click()
  }

  const handleLogOut = () => {
    try {
      Auth.logout()
      Tracker.trackEvent(TRACKEREVENTS.LOGOUT_SUCCESSFUL, {})
      localStorage.clear()
    } catch (error) {
      Tracker.trackEvent(TRACKEREVENTS.LOGOUT_FAILED, { error_message: 'An error occured. Please try again later' })
    }
  }

  const onFirstMount = () => {
    Tracker.trackEvent(TRACKEREVENTS.ACCOUNT_PAGE_VIEWED, getAccountSettings())
    //get history from url and get the params
    const urlParams = new URLSearchParams(window.location.search)
    const history = urlParams.get('newEmail')
    if (history === 'true') {
      showSuccess(T.success.emailUpdated)
    }
  }

  const handleChangeLanguage = async (lang) => {
    await Utils.changeLanguage(lang)
    AppStore.changeLang(lang)
  }

  useEffect(() => {
    onFirstMount()
  }, [])

  return (
    <Box animation="fade" flex mv={64} mh={80} gap={32}>
      {/* Title */}
      <Title style={{ color: AppStore.theme.o.on_secondary_container }}>{T.settings.accountData}</Title>

      <AccountImageContaienr
        onClick={handleImageClick}
        onMouseEnter={() => setIsProfileImgHovered(true)}
        onMouseLeave={() => setIsProfileImgHovered(false)}
      >
        <img
          src={imageSrc}
          alt="profile"
          width={72}
          height={72}
          style={{
            borderRadius: '50%',
          }}
        />

        {isProfileImgHovered && (
          <UploadIcon>
            <Icons.uploadFile fill={'white'} />
          </UploadIcon>
        )}

        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleImageChange}
          style={{ display: 'none' }} // Hide the file input
        />
      </AccountImageContaienr>

      <ContainerEditableAccountSections row style={{ gap: 72 }}>
        <EditableSectionColumn flex>
          {/* Name */}
          <EditableInput
            label={T.settings.yourName}
            value={name}
            variant="name"
            onConfirmCallback={(_name) => {
              //check if email is valid email
              if (!!_name) {
                Auth.updateName(_name)
                setName(_name)
                showSuccess(T.success.nameModified)
              } else {
                showError(T.error.invalidName)
              }
            }}
          />

          {/* Email */}
          <EditableInput
            label={T.settings.yourMail}
            value={email}
            variant="email"
            disabled={AppStore.loggedUser.isSocial}
            onConfirmCallback={(_email) => {
              //check if email is valid email
              if (!!_email && _email.includes('@')) {
                Auth.updateEmail(_email)
                setEmail(_email)
                showSuccess(T.success.weSendEmailWhithNewEmail)
              } else {
                showError(T.error.invalidEmail)
              }
            }}
          />

          {/* Password */}
          <EditableInput
            label={T.settings.yourPassword}
            value={password}
            variant="password"
            onConfirmCallback={(_password) => {
              if (!!_password && _password.length > 4) {
                Auth.updatePassword(_password)
                setPassword(_password)
                showSuccess(T.success.passworldUpdated)
              } else {
                showError(T.error.invalidPassword)
              }
            }}
          />
        </EditableSectionColumn>

        <EditableSectionColumn flex>
          {/* Lingua */}
          <ContainerLanguage>
            <Text style={{ color: AppStore.theme.o.black, marginBottom: 12 }} fontSize={16} weight={'bold'}>
              {T.settings.selectLanguage}
            </Text>

            <Box flex onClick={() => setIsOpenLanguage((prev) => !prev)} removeHover relative>
              <SelectedItem>
                {AppStore.loggedUser.language === 'en' ? T.langauge.eng : T.langauge.ita}

                <Icons.dropdownArrow
                  width={32}
                  height={32}
                  fill={AppStore.theme.o.black}
                  style={{
                    transition: '300ms ease-in-out',
                    rotate: isOpenLanguage ? '-180deg' : '0deg',
                    marginLeft: 'auto',
                  }}
                />
              </SelectedItem>

              {isOpenLanguage && (
                <ContentLanguage onClick={(e) => e.stopPropagation()} removeHover>
                  <OptionLanguage onClick={() => handleChangeLanguage('it')}>{T.langauge.ita}</OptionLanguage>
                  <OptionLanguage onClick={() => handleChangeLanguage('en')}>{T.langauge.eng}</OptionLanguage>
                </ContentLanguage>
              )}
            </Box>
          </ContainerLanguage>
        </EditableSectionColumn>
      </ContainerEditableAccountSections>

      {/* WHATSAPP */}
      {/* {isVisible('general.notifications.whatsapp') && (
        <Title style={{ color: AppStore.theme.o.green }}>{T.settings.whatsapp}</Title>
      )} */}

      {/* WHATSAPP */}
      {/* {isVisible('general.notifications.whatsapp') && (
          <>
            <RequestNumber
              type={'whatsapp'}
              onReturnData={(data, isValid) => {
                const { prefix, phone } = data
                // settaggio dati nello state per il numero di telefono
                setPhoneNumber((prev) => {
                  return { ...prev, prefix: prefix, number: phone }
                })
              }}
              onReturnOtp={(otp, isValid) => {}}
              phoneNumber={phoneNumber}
              canModifyBtn={phoneNumber?.otpCode ? false : phoneNumber?.number ? true : false}
            />
          </>
        )} */}

      {/* DARK MODE */}
      {/* <Box flex>
          <Title style={{ color: AppStore.theme.o.green, marginTop: 48, marginBottom: AppStore.darkTheme ? 6 : 12 }}>
            {T.settings.visualLightDark}
          </Title>
          <Box id="change-theme-icon" width={145} onClick={AppStore.changeTheme} removeHover>
            {AppStore.darkTheme ? <Icons.darkTheme /> : <Icons.lightTheme />}
          </Box>
        </Box> */}

      {/* Logout */}
      <Button variant="neutral" size="small" onClick={handleLogOut} style={{ marginTop: 'auto', marginRight: 'auto' }}>
        {T.settings.logOut}
      </Button>
    </Box>
  )
}

export default AccountSection

const ContainerLanguage = styled(Box)``

const UploadIcon = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const AccountImageContaienr = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${() => AppStore.theme.o.green};
`

const ContainerEditableAccountSections = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 48px;
`

const EditableSectionColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 72px;

  p {
    width: 50%;
  }
`

const VersionTextContainer = styled(Box)`
  align-items: center;
  margin-top: 55px;
`

const VersionText = styled((props) => <Text {...props} />)`
  color: ${() => AppStore.theme.o.darkGrey} !important;
`

const SelectedItem = styled(Box)`
  position: relative;
  background-color: ${() => AppStore.theme.o.lightGrey};
  height: 50px;
  color: ${() => AppStore.theme.o.black};
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
`

const ContentLanguage = styled(Box)`
  position: absolute;
  top: calc(100% + 8px);
  right: 0px;
  width: 100%;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  gap: 4px;
  padding: 16px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
`

const OptionLanguage = styled(Box)`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-weight: 600;
  color: ${() => AppStore.theme.o.black};
  font-size: 14px;
  gap: 8px;
  flex-direction: row;
  border: 1px solid transparent;

  &:hover {
    background-color: ${() => AppStore.theme.o.surface};
    border: 1px solid ${() => AppStore.theme.o.lightGrey};
  }
`
