import AiGeneration from 'api/AiGeneration'
import Utils from 'api/Utils'
import { Media } from 'types'
import {
  ICreatePostStore,
  IPostData,
  IPostVariant,
  IVariantSocial,
  TActiveMediaType,
  TPreviewPost,
} from 'types/PostInterface'
import { DEFAULT_POST } from 'utils/Constants/CreatePostConstants'
import { generateByLink } from 'utils/PostUtils/AiTextPost'
import { create } from 'zustand'

export const useCreatePostStore = create<ICreatePostStore>((set, get) => ({
  globalPost: DEFAULT_POST,
  setGlobalPost: (newGlobalPost: IPostData) => set({ globalPost: newGlobalPost }),

  posts: [],
  setPosts: (newPosts: IPostVariant[]) => {
    set({ posts: newPosts })
  },

  uniqueVariants: [],
  setUniqueVariants: (newUniqueVariants: string[]) => set({ uniqueVariants: newUniqueVariants }),

  activePost: DEFAULT_POST,
  setActivePost: (newActivePost: IPostData | IPostVariant) => set({ activePost: newActivePost }),

  activePreview: 'GLOBAL',
  setActivePreview: (newActivePreview: TPreviewPost) => set({ activePreview: newActivePreview }),

  //to be removed
  activePreviewYT: 'YOUTUBE_STANDARD',
  setActivePreviewYT: (newActivePreviewYT: 'YOUTUBE_STANDARD' | 'YOUTUBE_SHORT') =>
    set({ activePreviewYT: newActivePreviewYT }),

  activeAccountId: 'GLOBAL',
  setActiveAccountId: (newActiveAccountId: string) => set({ activeAccountId: newActiveAccountId }),

  hasClickedPublish: false,
  sethasClickedPublish: (newhasClickedPublish: boolean) => set({ hasClickedPublish: newhasClickedPublish }),

  refreshMediaContent: false,
  setRefreshMediaContent: (newRefreshMediaContent: boolean) => set({ refreshMediaContent: newRefreshMediaContent }),

  currentGenerationState: { brandvoice: false, neutro: false, link: false },
  // TextGeneration utils
  requestTextGeneration: (type: 'brandvoice' | 'neutro' | 'link', argument: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        set({ currentGenerationState: { ...get().currentGenerationState, [type]: true } })
        const response =
          type === 'link'
            ? await generateByLink(argument)
            : type === 'brandvoice'
            ? await AiGeneration.generatePosts(argument)
            : await Utils.generateText({ ...argument })
        set({ currentGenerationState: { ...get().currentGenerationState, [type]: false } })
        resolve(response)
      } catch (e) {
        reject(e)
      }
    })
  },

  clearPost: () => {
    DEFAULT_POST.medias = [] as Media[] | []

    set({
      globalPost: DEFAULT_POST,
      activePost: DEFAULT_POST,
      posts: [],
      hasClickedPublish: false,
      refreshMediaContent: false,
    })
  },

  socialsAdded: [],
  setSocialsAdded: (newSocialsAdded: IVariantSocial[]) =>
    set({
      socialsAdded: newSocialsAdded,
    }),

  activeMediaType: '',
  setActiveMediaType: (newMediaType: TActiveMediaType) => set({ activeMediaType: newMediaType }),
}))
