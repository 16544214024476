/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { Metadata } from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/GenericComponents/Metadata/Metadata'
import { Utm } from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/GenericComponents/Utm/Utm'
import { openTemplateModal } from 'components/Modal/ModalManager'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T } from 'utils'
import { updatePost } from 'utils/PostUtils/Post'
import { canShowMetadata, getLinkProperties, onPasteLink } from 'utils/PostUtils/ShortUrl'
import { ExtraTitle } from './ExtraTitle'

/* ------------------- INTERFACES  */
interface Props {
  post: IPostVariant
}

export const FirstComment = ({ post }: Props) => {
  /* ----------------- VARIABLES  */
  const { variantId, data, info } = post

  const showMetadata = canShowMetadata(post)

  const showUtm = getLinkProperties(post)!.length > 0

  return (
    <FirstCommentContainer>
      <ExtraTitle text={T.postPage.extraFirstComment} />

      <TextAreaContainer>
        <ProfileImg src={info.account.picture} />

        <TextArea
          value={data.firstComment?.text}
          onChange={(e) => {
            const description = e.target.value
            const activeText = post.data.firstComment?.text ?? ''

            updatePost(post.variantId, { firstComment: { ...post?.data?.firstComment, text: e.target.value } })

            if (description.length > activeText.length + 1)
              onPasteLink({ activePost: post, newText: description, firstComment: true })
          }}
        />

        <TemplatesIconContainer onClick={() => openTemplateModal(variantId)}>
          <Icons.template />
        </TemplatesIconContainer>
      </TextAreaContainer>

      {/* Metadata */}
      {showMetadata && <Metadata post={post} firstComment />}

      {/* UTM */}
      {showUtm && <Utm post={post} firstComment />}
    </FirstCommentContainer>
  )
}

const FirstCommentContainer = styled(Box)`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
`

const TextAreaContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 14px;
  border: 1px dashed ${() => AppStore.theme.o.darkGrey};
  background: ${() => AppStore.theme.o.lightGrey};
`

const ProfileImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

const TextArea = styled.textarea`
  width: 100%;
  min-height: 40px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${() => AppStore.theme.o.darkGrey};
  color: ${() => AppStore.theme.o.black};
`

const TemplatesIconContainer = styled(Box)`
  cursor: pointer;
  width: 26px;
  height: 26px;

  svg {
    transition: 300ms ease-in-out;
    fill: ${() => AppStore.theme.o.darkGrey};
  }

  &:hover {
    svg {
      fill: ${() => AppStore.theme.o.grey};
    }
  }
`
