/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { truncateText } from 'pages/ai-discover/commonFunction'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T } from 'utils'
import { MediaContainerPost } from '../../GenericComponents/MediaContainerPost'

/* ------------------- INTERFACES  */
interface Props {
  postPreview?: IPostVariant
}

export const ResultsPreviewFacebook = ({ postPreview }: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as IPostVariant,
  }))

  /* ----------------- VARIABLES  */
  const post = postPreview ?? activePost

  const urls = post?.data?.urls

  const metadataVisibleIndex = urls && urls.findIndex((url) => url.metaData.visible === true)

  const metadata = urls && metadataVisibleIndex !== -1 && urls[metadataVisibleIndex!]

  // Extra location
  const location = post?.data?.place?.location?.city

  // Extra sponsor
  const sponsor = post?.data?.brand && T.postPage.extraPaidSponsor

  // Today date
  const date = new Date().toLocaleDateString('it-IT', { day: 'numeric', month: 'long' })

  // Minor info array container
  const minorInfoProfile = [location && location, sponsor && sponsor, date].filter((info) => info)

  // Sponsor name
  const sponsorName = post?.data?.brand && post?.data?.brand?.name

  // Profile Name
  const profileName = `${truncateText(post?.info?.account?.name, 40)} ${
    sponsorName ? `${T.singleWord.with} ${sponsorName}` : ''
  }`

  return (
    <ResultsPreviewFacebookContainer>
      <HeaderPreview>
        <ProfileContent>
          <ProfileInfo>
            <ProfileImg src={post?.info?.account?.picture} />

            <ProfileMinorInfo>
              <ProfileName>{profileName}</ProfileName>

              <ProfileDate>
                {minorInfoProfile.map((info) => (
                  <span>{info} &#x2022;</span>
                ))}
                <Icons.fbMap />
              </ProfileDate>
            </ProfileMinorInfo>
          </ProfileInfo>

          <Icons.threeDots />
        </ProfileContent>

        <PreviewText>
          {post?.data?.text?.length > 80 ? (
            <>
              {post?.data?.text?.slice(0, 80)} <SeeMoreText>... {T.postPage.more}</SeeMoreText>
            </>
          ) : (
            post?.data?.text
          )}
        </PreviewText>
      </HeaderPreview>

      {postPreview && !metadata && post?.data?.medias?.length === 0 ? (
        <></>
      ) : (
        <MediaContainerPost width={'98%'} height={390} arrowsPositions={20} postPreview={postPreview} />
      )}

      <FooterPreview>
        <UpperFooter>
          <Feedbacks>
            {AppStore.darkTheme ? (
              <Icons.facebookLikeDarkMode style={{ width: 35, height: 35 }} />
            ) : (
              <Icons.facebookLike style={{ width: 35, height: 35 }} />
            )}
            177
          </Feedbacks>

          <Reactions>
            {T.postPage.comments}: 42
            <span style={{ marginLeft: 5 }} /> {T.postPage.share}: 52
          </Reactions>
        </UpperFooter>

        <LowerFooter>
          <InteractionContainer>
            <Icons.fbLike fill={AppStore.theme.o.black} />
            {T.postPage.like}
          </InteractionContainer>
          <InteractionContainer>
            <Icons.fbComment fill={AppStore.theme.o.black} /> {T.postPage.comment}
          </InteractionContainer>
          <InteractionContainer>
            <Icons.fbShare fill={AppStore.theme.o.black} /> {T.postPage.share}
          </InteractionContainer>
        </LowerFooter>
      </FooterPreview>
    </ResultsPreviewFacebookContainer>
  )
}

const ResultsPreviewFacebookContainer = styled(Box)`
  width: 100%;
  border: 1px solid ${() => AppStore.theme.o.grey};
`

const HeaderPreview = styled(Box)`
  padding: 12px;
  gap: 8px;
`

const PreviewText = styled(Box)`
  word-wrap: break-word;
  color: ${() => AppStore.theme.o.black};
  border-radius: 0;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
`

const SeeMoreText = styled.span`
  margin-left: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
`

const ProfileContent = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ProfileInfo = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const ProfileImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

const ProfileMinorInfo = styled(Box)`
  gap: 4px;
`

const ProfileName = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
`

const ProfileDate = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
`

const FooterPreview = styled(Box)`
  margin-top: 4px;
  padding: 0 12px;
`

const UpperFooter = styled(Box)`
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px;
  border-bottom: 1px solid ${() => AppStore.theme.o.grey};
  position: relative;

  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px; /* 81.264% */
`

const Feedbacks = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  position: absolute;
`

const Reactions = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`

const LowerFooter = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
`

const InteractionContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px; /* 81.264% */
`
